import * as React from "react"
import { Link } from "gatsby"
import Form  from "./form"
import Newsletter  from "./newsletter"
import { useStaticQuery, graphql } from "gatsby"

const Header = ({ siteTitle }) => {
  const openMenu = () => {
    document.body.classList.add('menu-open')
  }
  return (
  <>
    <header className='p30 flex align-center masthead'>
      <Link to="/" className='link'>
        <div className='logo--main'/>
      </Link>
      <p className='m0 mla pointer' role='presentation' onClick={openMenu}>Get In Touch</p>
    </header>
    <Overlay />
    <p className='fixed--button button m0' role='presentation' onClick={openMenu}>Get In Touch</p>
  </>
  )
}

export default Header

const Overlay = () => {
  var data  = useStaticQuery(
    graphql`
      query Footer {
        header:datoCmsHomepage {
          contactForm
          newsletter
        }
      }
    `
  )
  const closeMenu = () => {
    document.body.classList.remove('menu-open')
    document.body.classList.remove('newsletter-open')
  }
  return (
    <>
      <div className='overlay--modal newsletter'>
        <div className='close' role='presentation' onClick={closeMenu}/>
        <div className='text-center mb40'>
          <p className='m0 mb20 large dark mt40 m-mt20'>Newsletter Signup</p>
          <div className='content max-400 ma'>
            {data.header.newsletter} 
          </div>
        </div>
        <Newsletter />
      </div>
      <div className='overlay--modal form'>
        <div className='close' role='presentation' onClick={closeMenu}/>
        <div className='text-center mb40 m-mb20'>
          <p className='m0 mb20 large dark mt40 m-mt20'>Get In Touch</p>
          <div className='content max-650 ma'>
            {data.header.contactForm}
          </div>
        </div>
        <Form />
      </div>
      <div className='overlay--bg' role='presentation' onClick={closeMenu}/>
    </>
  )
}